#footer {
    background-color: var(--ion-color-primary);
    padding-top: 65px;
    align-items: center;
    > .container {
        display: flex;
        gap: 150px;
        @media (max-width: 991px) {
            gap: 50px;
            flex-direction: column;
            align-items: center;
        }
        > div {
            flex: 1;

            &:first-child {
                max-width: 270px;

                > a {
                    img {
                        width: 140px;
                        height: 65px;
                    }
                }
                p {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 120%;
                    letter-spacing: -0.025em;
                    color: #ffffff;
                    margin-top: 16px;
                    flex-direction: column;
                    @media (max-width: 991px) {
                        text-align: center;
                    }
                }
                .social {
                    display: flex;
                    gap: 8px;
                    @media (max-width: 991px) {
                        align-items: center;
                        justify-content: center;
                    }

                    a {
                        img {
                            width: 24px;
                            height: 25px;
                        }
                    }
                }

                > a > img {
                    @media (max-width: 991px) {
                        margin: 0 auto;
                    }
                }
            }

            &:last-child {
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                gap: 60px;
                width: 100%;
                @media (max-width: 991px) {
                    flex-direction: column;
                    align-items: center;
                }

                > div {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    width: 100%;
                    > p {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 100%;
                        letter-spacing: -0.025em;
                        color: #ffffff;
                        text-align: left;
                        @media (max-width: 991px) {
                            text-align: center;
                            font-size: 13px;
                        }
                    }
                    > div {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        width: 100%;

                        a {
                            text-align: left;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 150%;
                            letter-spacing: -0.025em;
                            color: #ffffff;
                            @media (max-width: 991px) {
                                text-align: center;
                                padding: 10px;
                                width: 100%;
                            }
                        }
                    }

                    > a {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 14px;
                        letter-spacing: -0.025em;
                        color: #ffffff;
                        height: 40px;
                        background-color: transparent;
                        border: 1px solid #fff;
                        border-radius: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        min-width: 200px;
                        transition: 0.3s;
                        &:hover {
                            background-color: #fff;
                            color: var(--ion-color-primary);
                        }
                    }
                }
            }
        }
    }
    > .creditos {
        .container {
            display: flex;
            justify-content: space-between;
            margin-top: 50px;
            padding-bottom: 15px;
            padding-top: 25px;
            border-top: 1px solid white;
            @media (max-width: 991px) {
                flex-direction: column;
            }
            p {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: -0.025em;
                color: #ffffff;
                a {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 150%;
                    /* identical to box height, or 21px */

                    text-align: right;
                    letter-spacing: -0.025em;

                    color: #ffffff;
                }
            }
        }
    }
}
